import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from 'src/layout/Layout';
import SEO from 'src/components/global/seo';
import AnalyticsScript from 'src/components/Analytics';

const Releases = ({ data }) => {
  const releases = data.allRelease.nodes
    .sort(({ published: a }, { published: b }) => new Date(b) - new Date(a))
    .reverse();

  return (
    <Layout className="releases">
      <SEO title="Releases" />
      <div className="releases-wrapper">
        {releases
          .map(release => (
            <Link to={release.slug}>
              <GatsbyImage alt={''} image={release.cover?.childImageSharp?.gatsbyImageData} />
            </Link>
          ))
          .reverse()}
      </div>
      <AnalyticsScript />
    </Layout>
  );
};

export default Releases;

export const releaseQuery = graphql`
  query {
    allRelease {
      nodes {
        slug
        published
        cover {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
